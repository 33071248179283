import * as React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/hero"
import Content, { HTMLContent } from "../components/content"

const AboutPageTemplate = ({ title, content, contentComponent }) => {
  const PageContent = contentComponent || Content

  return (
    <>
      <Seo title={title} />
      <Hero />
      <PageContent className="position-relative-zindex" content={content} />
    </>
  )
}

AboutPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const AboutPage = ({ data }) => {
  const { frontmatter, html } = data.markdownRemark

  return (
    <Layout title={"About"}>
      <AboutPageTemplate
        contentComponent={HTMLContent}
        title={frontmatter.title}
        content={html}
      />
      {frontmatter.documents.map((document, i) => (
        <>
          <div
            key={i}
            className="mb-3 ratio"
            style={{"--bs-aspect-ratio": "50%"}}
          >
            <iframe
              title={document.title}
              src={document.file}
              loading="lazy"
            />
          </div>
          <h4>{document.title}</h4>
        </>
      ))}
    </Layout>
  )
}

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default AboutPage

export const pageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        documents {
          title
          description
          file
        }
      }
    }
  }
`
